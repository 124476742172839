<template>
  <div>
    <ModuleComponent
      :module-data="moduleData"
      @changed="item = $event"
      @beforeSaveItem="beforeSaveItem"
      @beforeItemLoad="beforeItemLoad"
    >
      <template v-slot:tableFilters="{ filters }">
        <b-container fluid>
          <b-row>
            <b-col cols="3" sm="3" md="3">
              <b-form-group :label="t('GLOBAL.NAME')">
                <b-form-input
                  name="name"
                  v-model="filters.name.value"
                  :placeholder="t('GLOBAL.NAME')"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </template>
      <template v-slot:form="{ item }">
        <b-row>
          <b-col cols="12" sm="8" md="8">
            <b-form-group :label="t('GLOBAL.NAME')">
              <b-form-input
                name="name"
                v-model="item.name"
                :placeholder="t('GLOBAL.NAME')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="4" md="4">
            <b-form-group :label="t('GLOBAL.CODE')">
              <b-form-input
                name="name"
                v-model="item.code"
                :placeholder="t('GLOBAL.CODE')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="12" md="12">
            <b-form-group :label="t('GLOBAL.DESCRIPTION')">
              <b-form-input
                name="name"
                v-model="item.description"
                :placeholder="t('GLOBAL.DESCRIPTION')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="12" md="12">
            <b-form-group :label="t('GLOBAL.RUNMETHOD')">
              <b-form-input
                name="name"
                v-model="item.runmethod"
                :placeholder="t('GLOBAL.RUNMETHOD')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="12" md="12">
            <b-form-group :label="t('GLOBAL.TARGETSTATUS')">
              <v-select2
                v-model="item.targetStatusId"
                :text="item.targetStatus ? item.targetStatus.name : ''"
                endPoint="definitions"
                sort="name"
                :filters="{
                  definitionTypeId: {
                    value: Constants.DefinitionTypes.HarvestRequestStatus
                  }
                }"
              ></v-select2>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="12" md="12">
            <b-form-group :label="t('GLOBAL.TARGETSUBSTATUS')">
              <v-select2
                v-model="item.targetSubStatusId"
                :text="item.targetSubStatus ? item.targetSubStatus.name : ''"
                endPoint="definitions"
                sort="name"
                :filters="{
                  definitionTypeId: {
                    value: Constants.DefinitionTypes.HarvestRequestSubStatus
                  }
                }"
              ></v-select2>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="12" md="12">
            <b-form-group :label="t('GLOBAL.PARENT')">
              <v-select2
                v-model="parentActivities"
                endPoint="harvestRequestActivityDefinitions"
                :placeholder="t('GLOBAL.PARENT')"
                multiple
                :filters="{
                  id: { value: item.id, type: 'number', searchBy: 'notEqual' }
                }"
              ></v-select2>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </ModuleComponent>
  </div>
</template>

<script>
// http://core.datadns.net:5000/swagger/index.html#model-Definition
export default {
  name: "harvestRequestActivityDefinitions",
  methods: {
    beforeItemLoad(item) {
      this.parentActivities = item.parentActivities.map(p => {
        return p.parentActivityId;
      });
    },
    beforeSaveItem(item) {
      item.parentActivities = this.parentActivities.map(p => {
        return { activityId: item.id, parentActivityId: p };
      });
    }
  },
  data() {
    return {
      parentActivities: [],
      item: {},
      moduleData: {
        name: "harvestRequestActivityDefinitions",
        primaryKey: "id",
        table: {
          sortBy: "id",
          sortDesc: true,
          graphqlQuery: `
                          id,name,code,targetStatus{name},targetSubStatus{name}
                        `,
          headers: [
            { text: "ID", value: "id", width: "70" },
            { text: this.t("GLOBAL.NAME"), value: "name" },
            { text: this.t("GLOBAL.CODE"), value: "code" },
            { text: this.t("GLOBAL.TARGETSTATUS"), value: "targetStatus.name" },
            {
              text: this.t("GLOBAL.TARGETSUBSTATUS"),
              value: "targetSubStatus.name"
            }
          ],
          customColumns: [],
          filters: {
            name: {
              type: "string",
              searchBy: "beginsWith",
              value: null
            }
          }
        },
        form: {
          maxWidth: "80%"
        }
      }
    };
  }
};
</script>
