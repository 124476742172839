var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ModuleComponent',{attrs:{"module-data":_vm.moduleData},on:{"changed":function($event){_vm.item = $event},"beforeSaveItem":_vm.beforeSaveItem,"beforeItemLoad":_vm.beforeItemLoad},scopedSlots:_vm._u([{key:"tableFilters",fn:function(ref){
var filters = ref.filters;
return [_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"cols":"3","sm":"3","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.NAME')}},[_c('b-form-input',{attrs:{"name":"name","placeholder":_vm.t('GLOBAL.NAME')},model:{value:(filters.name.value),callback:function ($$v) {_vm.$set(filters.name, "value", $$v)},expression:"filters.name.value"}})],1)],1)],1)],1)]}},{key:"form",fn:function(ref){
var item = ref.item;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"8","md":"8"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.NAME')}},[_c('b-form-input',{attrs:{"name":"name","placeholder":_vm.t('GLOBAL.NAME')},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.CODE')}},[_c('b-form-input',{attrs:{"name":"name","placeholder":_vm.t('GLOBAL.CODE')},model:{value:(item.code),callback:function ($$v) {_vm.$set(item, "code", $$v)},expression:"item.code"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.DESCRIPTION')}},[_c('b-form-input',{attrs:{"name":"name","placeholder":_vm.t('GLOBAL.DESCRIPTION')},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.RUNMETHOD')}},[_c('b-form-input',{attrs:{"name":"name","placeholder":_vm.t('GLOBAL.RUNMETHOD')},model:{value:(item.runmethod),callback:function ($$v) {_vm.$set(item, "runmethod", $$v)},expression:"item.runmethod"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.TARGETSTATUS')}},[_c('v-select2',{attrs:{"text":item.targetStatus ? item.targetStatus.name : '',"endPoint":"definitions","sort":"name","filters":{
                definitionTypeId: {
                  value: _vm.Constants.DefinitionTypes.HarvestRequestStatus
                }
              }},model:{value:(item.targetStatusId),callback:function ($$v) {_vm.$set(item, "targetStatusId", $$v)},expression:"item.targetStatusId"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.TARGETSUBSTATUS')}},[_c('v-select2',{attrs:{"text":item.targetSubStatus ? item.targetSubStatus.name : '',"endPoint":"definitions","sort":"name","filters":{
                definitionTypeId: {
                  value: _vm.Constants.DefinitionTypes.HarvestRequestSubStatus
                }
              }},model:{value:(item.targetSubStatusId),callback:function ($$v) {_vm.$set(item, "targetSubStatusId", $$v)},expression:"item.targetSubStatusId"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.PARENT')}},[_c('v-select2',{attrs:{"endPoint":"harvestRequestActivityDefinitions","placeholder":_vm.t('GLOBAL.PARENT'),"multiple":"","filters":{
                id: { value: item.id, type: 'number', searchBy: 'notEqual' }
              }},model:{value:(_vm.parentActivities),callback:function ($$v) {_vm.parentActivities=$$v},expression:"parentActivities"}})],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }